<template>
  <div class="">
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="table-responsive text-nowrap">
      <md-table
        :columns="columns"
        :data-source="source.items"
        size="small"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        bordered
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
          <a-tag v-else color="grey">Tidak Aktif</a-tag>
        </template>
        <template #action>
          <span>
            <a-tooltip title="Lihat Pengguna">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <i class="fe fe-eye" />
              </a>
            </a-tooltip>
            <a-tooltip title="Ubah Pengguna">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Detail Regional dan Area">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-globe" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, computed, watch } from 'vue'
import apiClient from '@/services/axios'
import moment from 'moment'

const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Nama Lengkap',
    title_xls: 'Nama Sales',
    dataIndex: 'fullname',
  },
  {
    title: 'Username',
    title_xls: 'User Name',
    dataIndex: 'username',
  },
  {
    title: 'Level User',
    title_xls: 'Level Sales',
    dataIndex: 'role_name',
  },
  {
    title: 'Area',
    dataIndex: 'area',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    slots: { customRender: 'status' },
  },
]
import qs from 'qs'
export default {
  props: {
    param: {
      type: Object,
      default: () => {},
    },
    loading: [Boolean],
  },
  emits: ['update:loading', 'update:param'],
  setup(props, { emit }) {
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const source = ref({
      _meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },
      items: [],
    })
    const current1 = ref(1)
    const startRow = ref(1)

    columns.value = acolumns
    const params = reactive({
      page: 1,
      perPage: 10,
    })

    const meta = computed(() => source.value._meta)
    const isFetching = ref(false)

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    const errorMessage = ref(null)
    const refresh = (param = {}) => {
      isFetching.value = true
      emit('update:loading', true)
      if (param._export === 'xls') {
        apiClient
          .get('/api/sales-organisasi', {
            params: {
              ...param,
              _columns: columns.value
                .filter(
                  c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false,
                )
                .map(({ dataIndex: key, title, title_xls }) => ({
                  key,
                  title: title_xls ? title_xls : title,
                })),
            },
            paramsSerializer: function(params) {
              return qs.stringify({ ...params }, { arrayFormat: 'indices' })
            },
            responseType: 'blob',
          })
          .then(response => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }),
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `sales-organisasi_${moment().format('DDMMYY_HHmm')}.xlsx`)
            document.body.appendChild(link)
            link.click()
          })
          .catch(async error => {
            console.error({ error })
            errorMessage.value = null
            if (error.response) {
              const { status, statusText } = error.response
              const message = JSON.parse(await error.response.data.text()).message
              errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
            }
            isFetching.value = false
            emit('update:loading', false)
          })
          .finally(() => {
            isFetching.value = false
            emit('update:loading', false)
          })
      } else {
        apiClient
          .get('/api/sales-organisasi', {
            params: {
              page: params.page,
              'per-page': params.perPage,
              ...param,
            },
          })
          .then(({ data }) => {
            source.value = data
            source.value.items = data.items
          })
          .finally(() => {
            isFetching.value = false
            emit('update:loading', false)
          })
      }
    }

    onMounted(() => {
      refresh()
    })

    watch(
      () => props.param,
      val => {
        if (val.time_requested) {
          val.time_requested = undefined
          return
        }
        refresh(val)
      },
      { deep: true },
    )

    const handleTableChange = (pag, filters, sorter) => {
      params.page = pag.current
      params.perPage = pag.pageSize
      const { _export, ...param } = props.param
      emit('update:param', param)
      refresh(param)
      startRow.value = (pag.current - 1) * pag.pageSize + 1
    }

    return {
      searchText,
      searchInput,
      source,
      columns,
      handleReset,
      handleSearch,
      current1,
      startRow,
      meta,
      handleTableChange,
      isFetching,
      errorMessage,
    }
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
