<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <!-- TODO: Komponen Filter Tabel -->
            <m-filter class="mb-4" @update:filter="updateFilter" v-model:loading="loading" />
            <m-table v-model:param="filter" v-model:loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTable from './components/Forms/Table' // local component
import MFilter from './components/Forms/Filter' // local component

import { ref } from 'vue'

export default {
  name: 'VbSalesOrganisasi',
  components: { MTable, MFilter },
  setup() {
    const filter = ref({})
    const loading = ref(false)
    const updateFilter = f => {
      filter.value = Object.assign({}, f, { time_requested: new Date() })
    }
    return {
      filter,
      loading,
      updateFilter,
    }
  },
}
</script>
