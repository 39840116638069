<template>
  <div class="row">
    <div class="col">
      <filter-role v-model:value="filters.role" placeholder="Pilih Level"></filter-role>
    </div>
    <div class="col">
      <filter-area v-model:value="filters.area" placeholder="Pilih Area"></filter-area>
    </div>
    <div class="col">
      <a-select
        placeholder="Pilih Status"
        :options="[
          { value: null, label: 'Semua' },
          { value: 1, label: 'Aktif' },
          { value: 0, label: 'Tidak Aktif' },
        ]"
        v-model:value="filters.active"
      ></a-select>
    </div>
    <div class="col"><a-input placeholder="cari ..." v-model:value="filters.q"></a-input></div>
    <div class="mt-1">
      <a-button class="mr-2" type="primary" title="cari" @click="search" :loading="loading">
        <i v-if="!loading" class="fa fa-search" aria-hidden="true"></i>
        <span v-else>Memuat Data ...</span>
      </a-button>
      <a-button type="primary" title="Download Xls" @click="fetchXlsx" :loading="loading">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
      </a-button>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'

import FilterArea from '@/components/filter/FilterArea'
import FilterRole from '@/components/filter/FilterRole'

export default {
  name: 'MFilter',
  components: {
    FilterArea,
    FilterRole,
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        active: null
        q: null
        role: []
        area: []
      },
    },
    loading: [Boolean],
  },
  emits: ['update:filter'],
  setup(props, { emit }) {
    const filters = reactive({
      active: null,
      active: null,
      q: null,
      role: [],
      area: [],
    })

    const search = () => {
      emit('update:filter', Object.assign({}, filters))
    }

    const fetchXlsx = () => {
      emit('update:filter', Object.assign({}, filters, { _export: 'xls' }))
    }

    return {
      filters,
      search,
      fetchXlsx,
    }
  },
}
</script>

<style scoped>
.row .ant-select,
.row .ant-input {
  width: 100%;
  margin-top: 5px;
}
</style>
